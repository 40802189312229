<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-spinner v-if="isloading" />
          <div v-if="error">
            <mdb-alert color="danger">{{error}}</mdb-alert>
          </div>
          <mdb-container fluid v-if="!isloading">
            <mdb-row>
              <mdb-col>
                <h1>Statistiques</h1>
              </mdb-col>
            </mdb-row>
            <mdb-row>
              <mdb-col xl="12" md="12">
                <mdb-line-chart
                  :data="lineChartData"
                  :options="lineChartOptions"
                  :width="600"
                  :height="300"
                ></mdb-line-chart>
              </mdb-col>
            </mdb-row>
          </mdb-container>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbSpinner,
  mdbAlert,
  mdbLineChart,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'holasunstats',
  data() {
    return {
      error: null,
      success: null,
      isloading: true,
      colors: [
        'rgba(255, 99, 132, |||)',
        'rgba(151,187,205, |||)',
        'rgba(128, 203, 196, |||)',
        'rgba(255, 152, 0, |||)',
        'rgba(233, 30, 99, |||)',
        'rgba(170, 0, 255, |||)',
      ],
      lineChartData: {
        labels: [
          '2022-03-11',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          '2022-03-11',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          '2022-03-11',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
        ],
        datasets: [
          {
            label: 'My First dataset',
            backgroundColor: 'rgba(170, 0, 255, 0.1)',
            borderColor: 'rgba(170, 0, 255, 1)',
            borderWidth: 0.7,
            data: [65, null, null, 81, null, 55, 40],
          },
          {
            label: 'My Second dataset',
            backgroundColor: 'rgba(151,187,205,0.2)',
            borderColor: 'rgba(151,187,205,1)',
            borderWidth: 0.8,
            data: [28, 48, 40, 19, 86, 27, 90],
          },
        ],
      },
      lineChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)',
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)',
              },
            },
          ],
        },
      },
    };
  },
  components: {
    owgheader,
    topnavbar,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbSpinner,
    mdbAlert,
    mdbLineChart,
  },
  mounted() {
    this.loadstatsdata();
  },
  methods: {
    loadstatsdata() {
      this.isloading = true;
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/holasun/stats', {}).then((response) => {
        if (response.status === 'OK') {
          response.data.forEach((departure) => {
            console.log(departure);
            const key = departure.flight.segments[0].departure_gateway.code + departure.flight.segments[0].arrival_gateway.code;
            console.log(key);
          });
          self.isloading = false;
          console.log('process data');
        } else {
          self.error = response.msg;
        }
        self.isloading = false;
      });
    },
  },
};
</script>
